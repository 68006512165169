.footerContainer {
  margin: 10px;
}

.footerTitle {
  text-align: center;
  padding: 7px 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
}

.footerText {
  padding: 3px;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.2;
}

.contactList {
  display: flex;
  padding: 0px 10px;
}

.item {
  width: calc(100% / 4);
  padding: 10px;
}

.textItem {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.2;
  word-wrap: break-word;
}

.listSite {
  display: flex;
  justify-content: space-around;
}

.linkIGU > span {
  display: none;
}

.link > img {
  width: 17px;
  height: auto;
}

.avtor {
  display: inline-flex;
  align-items: center;
  font-style: italic;
}

.avtorIcon {
  margin: 3px;
}

.copyright {
  padding: 15px 25px;
  border-top: 1px solid black;

  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
}

@media screen and (max-width: 768px) {
  .contactList {
    flex-direction: column;
  }

  .item {
    width: auto;
  }

  .listSite {
    justify-content: flex-start;
  }

  .listSite > li {
    padding: 5px 10px;
  }

  .footerTitle {
    font-size: 14px;
  }

  .footerText {
    font-size: 13px;
  }

  .textItem {
    align-items: center;
    text-align: center;
    font-size: 12px;
  }

  .copyright {
    font-size: 11px;
  }
}
