
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-weight: normal;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

body {
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  background: linear-gradient(173deg, rgba(215,232,213,1) 0%, rgb(255, 255, 255) 100%) no-repeat;
}

html {
  /* min-height: 100%; */
  height: 100%;
}

#root {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90vw;
  /* min-height: 100vh; */
  height: 100%;
}

.container {
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
  /* min-height: 100vh; */
  height: 100%;
} 

@media screen and (max-width: 768px) {
  .container{
    width: 100%;
    max-width: 708px;
    padding: 0 20px;
  }
}

@media screen and (max-width: 480px) {
  .container{
    padding: 0 10px;
  }
}

label {
  margin: 10px 5px;
}

input,
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 5px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}


input.input-error,
select.input-error {
  border-color: #fc8181;
}

.error {
  color: #fc8181;
}
